:root { 
--purple_A100_33:#ef87f433; 
--teal_A400_33:#00e3a933; 
--pink_A200_33:#ff568333; 
--teal_500_33:#019d8b33; 
--teal_100:#b9d4d4; 
--pink_A100:#ff7499; 
--teal_500:#089c8f; 
--red_300_7f:#b254657f; 
--white_A700_ef:#ffffffef; 
--teal_900:#003636; 
--pink_100:#ffb2c4; 
--white_A700_4c:#ffffff4c; 
--teal_400_11:#17ac8511; 
--gray_900_26:#141a1a26; 
--blue_gray_100:#d4d4d4; 
--pink_A100_33:#ff749933; 
--amber_300_23:#f2c94c23; 
--white_A700_66:#ffffff66; 
--teal_400_19:#18ac8619; 
--gray_900_35:#1d1e0d35; 
--blue_gray_100_01:#c8cdd4; 
--blue_gray_100_02:#c9cdd4; 
--gray_50:#f7f8fa; 
--gray_300_33:#dadee133; 
--white_A700_33:#ffffff33; 
--white_A700_cc:#ffffffcc; 
--teal_800:#0e6249; 
--teal_A400_19:#00e3a919; 
--deep_purple_A100:#b888f4; 
--teal_500_7f_01:#00b0837f; 
--indigo_700_7f:#2f52997f; 
--blue_A100_33:#92b6ff33; 
--blue_gray_200:#a7c8c8; 
--gray_500:#979ca0; 
--lime_600:#d7b13e; 
--blue_gray_600:#4c7a7a; 
--gray_900:#062c28; 
--teal_A200:#40f8c9; 
--blue_gray_800_01:#26594c; 
--gray_100:#f3f5f8; 
--teal_A400_7f:#00e2a97f; 
--amber_300_7f:#f2c94c7f; 
--purple_A100_7f:#f087f47f; 
--cyan_900:#005454; 
--white_A700_7f:#ffffff7f; 
--purple_100:#fcaeff; 
--white_A700_b2:#ffffffb2; 
--white_A700_b5:#ffffffb5; 
--pink_A100_66:#ff769a66; 
--blue_A200:#4a81ee; 
--black_900_3f:#0000003f; 
--white_A700_99:#ffffff99; 
--amber_300_4c:#f2c94c4c; 
--teal_700:#007f70; 
--pink_700:#be4464; 
--blue_gray_700:#525f6c; 
--white_A700_19:#ffffff19; 
--blue_gray_900_42:#1b263e42; 
--gray_700_cc:#894963cc; 
--deep_purple_A200_66:#9747ff66; 
--teal_A400_66:#00e3a966; 
--cyan_300_66:#3fd2c166; 
--teal_600_7f:#0b94717f; 
--white_A700_0c:#ffffff0c; 
--pink_200_01:#ff9eb8; 
--teal_900_b7:#00514bb7; 
--teal_500_66:#019d8b66; 
--amber_300:#f2c94c; 
--white_A700_0f:#ffffff0f; 
--yellow_A700:#ffd200; 
--teal_A400_7f_01:#26e2b37f; 
--amber_300_66:#f2c94c66; 
--blue_gray_700_7f:#525f6c7f; 
--pink_A200_7f:#ff56837f; 
--teal_900_04:#0a4949; 
--lime_900_7f:#82633f7f; 
--teal_900_03:#004955; 
--teal_900_02:#0c4a4a; 
--deep_purple_A200_75:#9747ff75; 
--teal_900_01:#004040; 
--white_A700:#ffffff; 
--blue_A100_01:#79a6ff; 
--gray_300_74:#dfe2e574; 
--blue_gray_700_87:#525f6c87; 
--purple_A100_51:#ef87f451; 
--blue_A100:#91b6ff; 
--amber_300_33:#f2c94c33; 
--amber_A400:#ffc000; 
--teal_500_19:#019d8b19; 
--black_900:#000000; 
--pink_A200_4c:#ff56834c; 
--blue_gray_800:#275054; 
--cyan_900_82:#19545482; 
--purple_A100:#f087f4; 
--pink_200:#ffa1b8; 
--gray_900_02:#051627; 
--gray_900_03:#131c1c; 
--gray_900_04:#151e29; 
--blue_gray_400:#669898; 
--cyan_900_01:#195454; 
--white_A700_a2:#ffffffa2; 
--lime_800:#ae923a; 
--gray_900_01:#121b1b; 
--blue_A200_4c:#4a81ee4c; 
--purple_900_33:#3a126233; 
--pink_A100_66_01:#ff749966; 
--teal_800_01:#0d5b55; 
--teal_500_01:#019d8b; 
--gray_300:#dadee1; 
--teal_A400:#00e3a9; 
--gray_900_26_01:#14191926; 
--gray_900_7f:#002d2d7f; 
--cyan_900_7f:#1954547f; 
--white_A700_07:#ffffff07; 
--teal_500_7f:#019d8b7f; 
--blue_200:#9fbfff; 
}